import _ from "lodash";

import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore/lite";

import Logger from "./logger";
import AppTestimony from "./AppTestimonyWrapper";
import { formatNumber } from "utils/utils";

// From Project Settings > General
const firebaseConfig = {
  apiKey: "AIzaSyAl-XbaaFcrTVvtF7VbFcPuikCrZ1kBk-Y",
  authDomain: "texas-voter-tracker.firebaseapp.com",
  projectId: "texas-voter-tracker",
  storageBucket: "texas-voter-tracker.appspot.com",
  messagingSenderId: "942658972732",
  appId: "1:942658972732:web:6971d628d54e742757e739",
};

const app = initializeApp(firebaseConfig, "texas_voter_tracker");
const db = getFirestore(app);
const auth = getAuth();

export default class Firestore {
  static async AnonymouslySignIn() {
    return await signInAnonymously(auth).then(() => {
      // Signed in..
      Logger.debug("Signed in to TVT");
    });
  }

  static async Impact() {
    await Firestore.AnonymouslySignIn();
    Logger.debug("Querying Firestore for impact...");

    const currentRef = doc(db, "impact", "events");
    const current = (await getDoc(currentRef)).data();
    const historicalRef = doc(db, "impact", "preserved");
    const historical = (await getDoc(historicalRef)).data();

    // const hours_2020 = _.get(historical, ["2020", "time", "hours"]);
    const hours_2022 = _.get(historical, ["2022", "time", "hours"]);

    const inspected =
      _.get(historical, ["2022", "Voter_Inspected", "individual"]) +
      _.get(historical, ["2022", "Voter_Inspected", "friend"]);

    const resources = _.sum([
      _.get(current, ["Link_Clicked", "Am I Registered?"], 0),
      _.get(current, ["Link_Clicked", "Register to Vote"], 0),
      _.get(current, ["Link_Clicked", "Where to Vote"], 0),
      _.get(current, ["Link_Clicked", "What's on my Ballot?"], 0),
      _.get(current, ["Link_Clicked", "Check My Mail-in Ballot"], 0),
    ]);

    const searches_2020 =
      _.get(historical, ["2020", "Search_Made", "individual"]) +
      _.get(historical, ["2020", "Search_Made", "friend"]);
    const searches_2022 =
      _.get(historical, ["2022", "Search_Made", "individual"]) +
      _.get(historical, ["2022", "Search_Made", "friend"]);
    const current_searches =
      _.get(current, ["Search_Made", "individual"]) +
      _.get(current, ["Search_Made", "friend"]);

    const users_2020 = _.get(historical, ["2020", "users", "new"]);
    const users_2022 = _.get(current, ["users", "new"]);

    const likes_2020 = _.sum(_.values(_.get(historical, ["2020", "likes"])));
    const likes_2022 =
      _.get(historical, ["2022", "likes", "facebook"]) +
      _.get(historical, ["2022", "likes", "instagram"]);
    const likes_reddit_comments = _.sumBy(
      _.get(historical, ["2022", "likes", "reddit"]),
      (v) => _.max([0, v - 1])
    );
    const likes_reddit_posts = _.sum([
      _.get(historical, [
        "2022",
        "likes",
        "reddit_posts",
        "r/Austin",
        "known_upvotes",
      ]),
      _.get(historical, [
        "2022",
        "likes",
        "reddit_posts",
        "r/texas",
        "known_upvotes",
      ]),
    ]);
    const shares_2020 = _.sum(_.values(_.get(historical, ["2020", "shares"])));
    const shares_2022 = _.sum(_.values(_.get(historical, ["2022", "shares"])));

    // Also pull in total stats separately
    const statsRef = doc(db, "aggregate", "  Statewide");
    const stats = (await getDoc(statsRef)).data();

    return {
      voters: {
        title: formatNumber(_.get(stats, ["voters", "total"]), "M"),
        secondaryCaption: null,
      },
      ballots: {
        title: formatNumber(_.get(stats, ["ballots"]), "M"),
        secondaryCaption: null,
      },
      hours: {
        title: `${_.round(hours_2022, 1)}`,
        secondaryCaption: null,
      },
      likes: {
        title: `${
          likes_2020 + likes_2022 + likes_reddit_comments + likes_reddit_posts
        }`,
      },
      shares: { title: `${shares_2020 + shares_2022}` },
      users: {
        title: formatNumber(users_2020 + users_2022, "K"),
        secondaryCaption: null,
      },
      searches: {
        title: formatNumber(searches_2020 + current_searches, "K"),
        secondaryCaption: `${_.round(
          current_searches,
          -2
        ).toLocaleString()}: ${_.get(current, [
          "Search_Made",
          "individual",
        ]).toLocaleString()} individual, ${_.get(current, [
          "Search_Made",
          "friend",
        ]).toLocaleString()} via contacts.`,
      },
      inspected: {
        title: `${_.round((inspected / searches_2022) * 100, 1)}%`,
        secondaryCaption: null,
      },
      resources: {
        title: `${resources}`,
        secondaryCaption: null,
      },
    };
  }

  static async Testimonies() {
    await Firestore.AnonymouslySignIn();
    Logger.debug("Querying TVT Firestore for testimonies...");

    const q = await getDocs(collection(db, "testimonies"));
    const testimonies = _.map(q.docs, (d) => new AppTestimony(d.id, d.data()));
    return _.orderBy(testimonies, "score", "desc");
  }
}
