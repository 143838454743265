import {
  IconAward,
  // IconBike,
  IconBrain,
  IconBriefcase,
  IconBulb,
  IconDeviceDesktop,
  IconHeartHandshake,
  IconSchool,
} from "@tabler/icons-react";
import { BiSolidBank } from "react-icons/bi";
import { GiAxolotl, GiCowboyBoot } from "react-icons/gi";

const inline_menu = [
  {
    name: "Experience",
    children: [
      {
        title: "Professional",
        icon: IconBriefcase,
        target: "professional",
        color: "#4caf50", // green
      },
      {
        title: "Social Impact",
        icon: IconHeartHandshake,
        target: "impact",
        color: "#f50057", // pink
      },
      {
        title: "Awards",
        icon: IconAward,
        target: "awards",
        color: "#ffc107", // amber
      },
    ],
  },
  {
    name: "Portfolio",
    children: [
      {
        title: "Full Portfolio",
        icon: IconBulb,
        target: "portfolio",
        color: "#ffb300", // amber
      },
      { divider: true },
      {
        title: "Bank of Kigali",
        icon: BiSolidBank,
        target: "bank-of-kigali",
        color: "#689f38", // green
      },
      {
        title: "2022 TX Voter Tracker",
        icon: GiCowboyBoot,
        target: "2022-tracker",
        color: "#b26a00", // burnt orange
      },
      {
        title: "2020 TX Voter Tracker",
        icon: GiCowboyBoot,
        target: "2020-tracker",
        color: "#e65100", // dark orange 2
      },
      {
        title: "Made to Uplift",
        icon: GiAxolotl,
        target: "made-to-uplift",
        color: "#f73378", // pink
      },
    ],
  },
  {
    name: "Blog",
    visible: false,
    children: [
      {
        title: "Harvard Business School",
        icon: IconSchool,
        target: "blog/hbs",
      },
      {
        title: "Tech",
        icon: IconDeviceDesktop,
        target: "blog/tech",
      },
      // {
      //   title: "Biking to Alaska",
      //   icon: IconBike,
      //   target: "blog/bike",
      // },
      {
        title: "Musings",
        icon: IconBrain,
        target: "blog/musings",
      },
    ],
  },
  {
    name: "Testimonials",
    target: "testimonials",
  },
];

export default inline_menu;
