import _ from "lodash";
import moment from "moment";

import Ratings from "data/ratings";
import { nullthrows, scrub } from "./utils";

export default class TestimonyWrapper {
  constructor(id, data) {
    this.id = id;
    this.name = nullthrows(data, "name");
    this.image = nullthrows(data, "image");
    this.impact = _.get(data, "impact", 0);
    this.role = nullthrows(data, "role");
    this.team = _.get(data, "team");
    this.company = nullthrows(data, "company");
    this.date = nullthrows(data, "date");
    this.quotes = _.get(data, "quotes", []);
    this.additionaQuotes = _.get(data, "additional");
    this.categories = nullthrows(data, "categories");
    this.rating = _.get(data, "rating");
    this.manager = _.get(data, "manager", false);
    this.type = nullthrows(data, "type");
  }

  get displayedName() {
    let array = _.split(this.name, " ");
    return `${_.first(array)} ${array[1][0]}.`;
  }

  get displayedDate() {
    return moment(this.date).format("MMM YYYY");
  }

  get displayedImpact() {
    if (this.rating) {
      return `Rating: ${this.rating}`;
    }
    return Ratings.numberToStr(this.impact);
  }

  get markdown() {
    return _.map(this.quotes, (t) => {
      const scrubbed = scrub(t);
      if (_.startsWith(scrubbed, "#")) {
        return `\\${scrubbed}`;
      }
      return scrubbed;
    });
  }

  get additionalMarkdown() {
    if (!this.additionaQuotes) {
      return null;
    }

    return _.map(this.additionaQuotes, (t) => {
      const scrubbed = scrub(t);
      if (_.startsWith(scrubbed, "#")) {
        return `\\${scrubbed}`;
      }
      return scrubbed;
    });
  }
}
