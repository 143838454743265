export function formatNumber(num, suffix) {
  const suffixMap = {
    K: 1e3,
    M: 1e6,
    G: 1e9,
    T: 1e12,
    P: 1e15,
    E: 1e18,
  };

  const scale = suffixMap[suffix];
  if (!scale) {
    throw new Error(
      `Invalid suffix: ${suffix}. Supported suffixes are: ${Object.keys(
        suffixMap
      ).join(", ")}`
    );
  }

  const formattedNum = (num / scale)
    .toFixed(1)
    .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1");
  return `${formattedNum}${suffix}`;
}
