// theme constant
export const gridSpacing = 3;
export const toolbarHeight = 80;
export const toolbarHeightMobile = 90;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const personalTitles = [
  // "I am a Coder",
  // 2000,
  "I am a Builder",
  // 2000,
  // "I am an Entrepreneur",
  // 2000,
  // "I am a Consultant",
  // 2000,
  // "I am a Creator.",
  // 2000,
];
