import React, { forwardRef, useState } from "react";
import {
  Popper,
  ClickAwayListener,
  ListItemButton,
  ListItem,
  List,
  IconButton,
  ListItemText,
  Divider,
} from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import useCustomNavigation from "utils/custom_navigation";

const MenuButton = forwardRef(
  (
    { title, target, links, focusNext, focusPrevious, targetProps, ...props },
    ref
  ) => {
    const navigate = useCustomNavigation();
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? `${title.toLowerCase()}-popper` : undefined;

    return (
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <div onMouseLeave={() => setAnchorEl(null)}>
          <ListItemButton
            aria-haspopup="true"
            aria-expanded={open ? "true" : "false"}
            ref={ref}
            {...props}
            role="menuitem"
            onFocus={(event) => setAnchorEl(event.currentTarget)}
            onMouseEnter={(event) => {
              props.onMouseEnter?.(event);
              setAnchorEl(event.currentTarget);
            }}
            component="a"
            href={target}
            onClick={
              target
                ? (e) => {
                    e.preventDefault();
                    navigate(target);
                  }
                : null
            }
            style={{
              borderRadius: 25,
              fontFamily: "Josefin Sans, sans-serif",
              textTransform: "uppercase",
            }}
          >
            {title} {target ? null : <KeyboardArrowDown />}
          </ListItemButton>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            disablePortal
            keepMounted
          >
            <List
              role="menu"
              aria-label={title}
              sx={{
                boxShadow: "md",
                borderRadius: 1,
                minWidth: 180,
                width: 200,
                top: 5,
              }}
            >
              {links.map((item, index) => {
                if (item.divider) {
                  return <Divider key={index} style={{ height: 5 }} />;
                }
                const Icon = item.icon;
                return (
                  <ListItem
                    role="none"
                    key={index}
                    style={{ backgroundColor: "#FAF9F6", padding: 0 }}
                  >
                    <ListItemButton
                      role="menuitem"
                      {...targetProps}
                      component="a"
                      href={item.target}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(item.target);
                      }}
                    >
                      {Icon ? (
                        <IconButton edge="start" size="small">
                          <Icon color={item.color} />
                        </IconButton>
                      ) : null}
                      <ListItemText
                        primary={item.title}
                        primaryTypographyProps={{ variant: "overline" }}
                        style={{ marginLeft: 2.5 }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Popper>
        </div>
      </ClickAwayListener>
    );
  }
);

export default MenuButton;
