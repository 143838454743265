import _ from "lodash";
import Logger from "./logger";

export function nullthrows(data, key) {
  const value = _.get(data, key);
  if (!value) {
    throw new Error(`Missing ${key} for ${data.name} ${data.date}`);
  }
  return value;
}

export function openLink(url) {
  window.open(url, "_blank");
}

export const category_to_color = {
  // Experiences
  Current: "orange",
  Nonprofit: "primary",
  "Won Award": "success",

  // Testimonies
  Consulting: "success",
  Engineering: "secondary",
  Mentorship: "orange",
  Strategy: "primary",
  "Social Impact": "warning",
};

export function light_color(index) {
  const light_colors = [
    "antiquewhite",
    "#e3f2fd",
    "#f9fbe7",
    "#f3e5f5",
    "#e0f2f1",
    "#fff3e0",
    "#ede7f6",
    "#fffde7",
    "#fce4ec",
    "#e0f7fa",
    "#e8f5e9",
  ];

  return light_colors[index % light_colors.length];
}

export function scrub(text) {
  if (Logger.mode().inProtectedMode) {
    return text.replace(/{{/g, "").replace(/}}/g, "");
  }

  return text.replace(/{{([\s\S]+?)}}/g, () => "[REDACTED]");
}
