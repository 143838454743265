// assets
import { LooksOne, LooksTwo } from "@mui/icons-material";
import {
  IconBulb,
  IconChartArrowsVertical,
  IconExternalLink,
  IconNotebook,
} from "@tabler/icons-react";
import { GiAxolotl, GiCowboyBoot } from "react-icons/gi";
import { BiSolidBank, BiTrip } from "react-icons/bi";

const portfolio = {
  id: "portfolio",
  title: "Portfolio",
  type: "group",
  children: [
    {
      id: "portfolio",
      title: "All Projects",
      type: "item",
      url: "/portfolio",
      icon: IconBulb,
      breadcrumbs: false,
      color: "#ffb300", // amber
    },
    {
      id: "bank-of-kigali",
      title: "Bank of Kigali",
      type: "item",
      url: "/bank-of-kigali",
      icon: BiSolidBank,
      breadcrumbs: false,
      color: "#689f38", // green
    },
    {
      id: "texas-voter-tracker",
      title: "Texas Voter Tracker",
      type: "collapse",
      icon: GiCowboyBoot,
      color: "#b26a00", // dark orange
      children: [
        {
          id: "2020-tracker",
          title: "2020 Release",
          type: "item",
          url: "/2020-tracker",
          icon: LooksOne,
          breadcrumbs: false,
          color: "#6a1b9a", // dark purple
        },
        {
          id: "2022-tracker",
          title: "2022 Release",
          type: "item",
          url: "/2022-tracker",
          icon: LooksTwo,
          breadcrumbs: false,
          color: "#6a1b9a", // dark purple
        },
        {
          id: "tvt-demo",
          title: "Live App",
          type: "item",
          url: "https://texasvotertracker.com",
          external: true,
          icon: IconExternalLink,
          breadcrumbs: false,
          color: "#00b8d4", // cyan
        },
      ],
    },
    {
      id: "made-to-uplift",
      title: "Made to Uplift",
      type: "collapse",
      icon: GiAxolotl,
      breadcrumbs: false,
      color: "#f73378", // pink
      children: [
        {
          id: "made-to-uplift",
          title: "The Journey",
          type: "item",
          url: "/made-to-uplift",
          icon: BiTrip,
          breadcrumbs: false,
          color: "#6a1b9a", // dark purple
        },
      ],
    },
    {
      id: "uplift-journal",
      title: "Uplift Journal",
      type: "item",
      url: "/uplift-journal",
      icon: IconNotebook,
      breadcrumbs: false,
      color: "#00a152", // green
      visible: false,
    },
    {
      id: "memories",
      title: "Memory Dividends",
      type: "item",
      url: "/memories",
      icon: IconChartArrowsVertical,
      breadcrumbs: false,
      color: "#2979ff", // blue
      visible: false,
    },
  ],
};

export default portfolio;
